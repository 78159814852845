import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledTwoColumnsGridWrapper from "../shared/StyledTwoColumnsGridWrapper";
import StyledVideoWrapper from "../shared/StyledVideoWrapper";

import { Icon } from '../../globals/icons';
import { StyledSubTitle1, StyledDesc1 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { flexCenter } from "../shared/CommonStyled";

import { baseDomain } from "../../endpoints";
import githublogo from "./images/github-brands.svg";
import hasuraBlock from "./images/hasura-black.svg";
import browse from "./images/link.svg";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const AddExisting = ({ type }) => {
  return (
    <StyledSectionWrapperLightGray id="livingPostgres">
      <StyledContainerWrapper>
        <StyledTwoColumnsGridWrapper>
          <div className="alignSelfCenter">
            <StyledSubTitle1 paddingBottom="pb16">Add to an existing, live postgres database</StyledSubTitle1>
            <StyledDesc1>
              Add Hasura to any existing postgres database as-is and expose select tables & views over {type}.<br/><br/>
              We took Gitlab CE (a complex rails + postgres application), hosted it on DigitalOcean and added {type} to it in just a few minutes. Check out the demo on the left!
            </StyledDesc1>
            <StyledDesc1 className="viewDocs" paddingBottom="pb16" linkVariant="grey_100">
              <a
                href={`https://${baseDomain}/docs/latest/graphql/core/getting-started/using-existing-database.html`}
                target={"_blank"}
                rel="noopener noreferrer"
                css={flexCenter}
              >
                <img className="leftIcon" src={hasuraBlock} alt="Hasura" />
                <span>View docs to connect an existing database</span>
                <img className="rightIcon" src={browse} alt="Browse" />
              </a>
            </StyledDesc1>
            <StyledDesc1>
              <a
                href={"https://github.com/hasura/gitlab-graphql"}
                target={"_blank"}
                rel="noopener noreferrer"
                css={flexCenter}
              >
              <img className="leftIcon" src={githublogo} alt="githublogo" />
              View on Github
              <Icon className="arrow rightIcon" variant="arrow_right" color="sky_80" size="sm" />
              </a>
            </StyledDesc1>
          </div>
          <div className="alignSelfCenter">
            <StyledVideoWrapper>
              <div className="videoAspectRatio">
                <iframe
                  loading="lazy"
                  title="Add to an existing, live postgres database"
                  src="https://www.youtube.com/embed/a2AhxKqd82Q?showinfo=0&rel=0"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </StyledVideoWrapper>
          </div>
        </StyledTwoColumnsGridWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default AddExisting;

import React from "react";
import styled from "styled-components";

import { Icon } from '../../globals/icons';
import { StyledDesc1 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";

const StyledPowerfulQueryFeatureWrapper = styled.div`
  .powerfulQueryList {
    padding: 24px 0;
    .powerfulQueryImg {
      padding-bottom: 16px;
    }
  }
`;

const PowerfulQueryFeature = ({ plansState }) => {
  const plansWrapper = plansState.map((plans, i) => {
    return (
      <div key={i} className="powerfulQueryList">
        <div className="powerfulQueryImg">
          <img className="img-responsive" src={plans.img} alt={plans.title} />
        </div>
        <StyledDesc1 as="h3" fontWeight="font_600" paddingBottom="pb16">{plans.title}</StyledDesc1>
        <StyledDesc1 paddingBottom="pb16">{plans.description}</StyledDesc1>
        <StyledDesc1>
          <a href={plans.link} target={"_blank"} rel="noopener noreferrer" css={flexCenter}>
            Read More<Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" />
          </a>
        </StyledDesc1>
      </div>
    );
  });
  return <StyledPowerfulQueryFeatureWrapper>{plansWrapper}</StyledPowerfulQueryFeatureWrapper>;
};

export default PowerfulQueryFeature;

import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import PowerfulQueryFeature from "./powerfulqueryfeature";

import { baseDomain } from "../../endpoints";
import { StyledSubTitle1, StyledDesc2 } from "../shared/StyledTypography";
import { textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import tags from "../opensource/images/tags.png";
import fetch from "../opensource/images/fetch-brands.png";
import fetchRest from "./images/fetch-product.png";
import tagsRest from "./images/insert-products-rest.png";
import queries from "./images/queries.svg";
import mutations from "./images/mutations.svg";
import update from "./images/update.svg";
import deleteIcon from "./images/delete.svg";

const graphQLPlansState = [
  {
    img: queries,
    title: "Queries",
    description:
      "Query across tables & views with powerful filtering, pagination and pattern search.",
    link: `https://${baseDomain}/docs/latest/graphql/core/queries/index.html`,
  },
  {
    img: mutations,
    title: "Insert Mutations",
    description:
      "Insert single or multiple objects. Make upsert operations on conflict to simplify app logic.",
    link: `https://${baseDomain}/docs/latest/graphql/core/mutations/insert.html`,
  },
  {
    img: update,
    title: "Update Mutations",
    description: "Update objects based on a condition. Update to Increment/multiply directly.",
    link: `https://${baseDomain}/docs/latest/graphql/core/mutations/update.html`,
  },
  {
    img: deleteIcon,
    title: "Delete Mutations",
    description: "Delete one object or in bulk based on a condition.",
    link: `https://${baseDomain}/docs/latest/graphql/core/mutations/delete.html`,
  },
];

const restPlansState = [
  {
    img: queries,
    title: "GET Data (Query)",
    description:
      "Query across tables & views with powerful filtering, pagination and pattern search.",
    link: `https://${baseDomain}/docs/latest/graphql/core/api-reference/restified.html`,
  },
  {
    img: mutations,
    title: "POST Data (Query or Insert)",
    description:
      "Insert single or multiple objects. Make upsert operations on conflict to simplify app logic.",
    link: `https://${baseDomain}/docs/latest/graphql/core/api-reference/restified.html`,
  },
  {
    img: update,
    title: "PUT Data (Update)",
    description: "Update objects based on a condition. Update to Increment/multiply directly.",
    link: `https://${baseDomain}/docs/latest/graphql/core/api-reference/restified.html`,
  },
  {
    img: deleteIcon,
    title: "DELETE Data (Delete)",
    description: "Delete one object or in bulk based on a condition.",
    link: `https://${baseDomain}/docs/latest/graphql/core/api-reference/restified.html`,
  },
];

const StyledPowerFulQueryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  .ptb16 {
    padding: 20px 0;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const PowerfulQuery = ({ type }) => {
  return (
    <StyledSectionWrapper id="powerfulQueries">
      <StyledContainerWrapper>
        <StyledPowerFulQueryWrapper>
          <div>
            <StyledSubTitle1>Powerful queries out-of-the-box</StyledSubTitle1>
            <PowerfulQueryFeature
              plansState={type === "GraphQL" ? graphQLPlansState : restPlansState}
            />
          </div>
          <div>
            <img
              className="queriesImg"
              style={{ width: "100%" }}
              src={type === "GraphQL" ? tags : tagsRest}
              alt="Query"
            />
            <StyledDesc2 className="ptb16" variant="grey_80" css={textCenter}>
              Remove old product tags & insert new tags
            </StyledDesc2>
            <img
              className="queriesImg"
              style={{ width: "100%" }}
              src={type === "GraphQL" ? fetch : fetchRest}
              alt="Query"
            />
            <StyledDesc2 className="ptb16" variant="grey_80" css={textCenter}>
              Fetch first 5 brands with top 10 products for each
            </StyledDesc2>
          </div>
        </StyledPowerFulQueryWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default PowerfulQuery;

import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledTwoColumnsGridWrapper from "../shared/StyledTwoColumnsGridWrapper";
import StyledButton from "../shared/StyledButton";

import { StyledSubTitle1, StyledDesc1 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { flexCenter } from "../shared/CommonStyled";

import hasuraIcon from "../reactgraphql/images/hasura-icon.svg";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const GraphQLCommunity = ({ title, desc, list }) => {
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledTwoColumnsGridWrapper>
          <div className="alignSelfCenter">
            <StyledSubTitle1 paddingBottom="pb16">{title}</StyledSubTitle1>
            <StyledDesc1 paddingBottom="pb16">{desc}</StyledDesc1>
            {
              list.map((list, index) => (
                <StyledDesc1 key={index} linkVariant="grey_hover" paddingBottom="pb16">
                  <a href={list.link} target="_blank" rel="noopener noreferrer" css={flexCenter}>
                  {list.imgSrc ? (
                    <img
                      loading="lazy"
                      className="leftIcon"
                      src={list.imgSrc}
                      alt={list.imgAlt}
                    />
                  ) : null}
                  {list.name}
                  </a>
                </StyledDesc1>
              ))
            }
          </div>
          <div className="alignSelfCenter">
            <div className="greenGradientDiscordBg">
              <StyledSubTitle1 variant="white" paddingBottom="pb16">Join the community!</StyledSubTitle1>
              <StyledDesc1 variant="white" fontWeight="font_500">
                If you need any help with developing on Hasura, the 6000+ member strong Hasura Discord is here to help. Our community members include some very experienced engineers from some of the world’s most exciting companies, and many of them have been using Hasura in Production for a long time.
              </StyledDesc1>
              <div className="buttonWrapper">
                <a
                  href={"https://discord.com/invite/hasura"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  aria-label="Join the discord"
                >
                  <StyledButton variant="white">Join Discord</StyledButton>
                </a>
              </div>
              <div className="fixedImg">
                <img
                  loading="lazy"
                  className={"img-responsive"}
                  src={hasuraIcon}
                  alt="Hasura icon"
                />
              </div>
            </div>
          </div>
        </StyledTwoColumnsGridWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default GraphQLCommunity;

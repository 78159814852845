import styled from "styled-components";

import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const StyledTwoColumnsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  .viewDocs {
    background-color: ${COLORS.white};
    border-left: 2px solid transparent;
    border-radius: 2px;
    border-right: 0;
    box-shadow: ${ELEVATION.l_0};
    display: block;
    margin: 40px 0;
    padding: 12px 24px;
    transition: all .3s ease-in-out;
    &:hover {
      border-left: 2px solid ${COLORS.sky_80};
      box-shadow: ${ELEVATION.l_2};
    }
    a {
      span {
        flex: 1;
      }
    }
  }
  .alignSelfCenter {
    align-self: center;
  }
  .greenGradientDiscordBg {
    background-color: #7289da;
    background-image: linear-gradient(to bottom, #7289da, #4458a0);
    background: -webkit-gradient(
      linear,
      0% 0%,
      0% 100%,
      from(#7289da),
      to(#4458a0)
    );
    background: -webkit-linear-gradient(top, #7289da, #4458a0);
    background: -moz-linear-gradient(top, #7289da, #4458a0);
    background: -ms-linear-gradient(top, #7289da, #4458a0);
    background: -o-linear-gradient(top, #7289da, #4458a0);
    border-radius: 2px;
    padding: 40px;
    position: relative;
    .buttonWrapper {
      padding-top: 40px;
    }
    .fixedImg {
      bottom: 0;
      position: absolute;
      right: 0;
    }
  }
  .mt6 {
    margin-top: 6px;
  }
  .gridImg {
    img {
      margin-bottom: 12px;
    }
  }
  .mobileShowTwoColumns {
    display: none;
  }
  ${mq.between("md", "lg")} {
    grid-gap: 40px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    .greenGradientDiscordBg {
      padding: 24px;
    }
    .mobileHideTwoColumns {
      display: none;
    }
    .mobileShowTwoColumns {
      display: block;
    }
  }
`;

export default StyledTwoColumnsGridWrapper;

import React from "react";

import StyledSectionWrapper from "../StyledSectionWrapper";
import StyledContainerWrapper from "../StyledContainerWrapper";
import StyledTwoColumnsGridWrapper from "../StyledTwoColumnsGridWrapper";
import StyledVideoWrapper from "../StyledVideoWrapper";

import { Icon } from '../../../globals/icons';
import { StyledSubTitle1, StyledDesc1, StyledDesc2 } from "../StyledTypography";
import { textCenter } from "../CommonStyled";

const TwoGridColumnsWrapper = ({ title, subDescription, list, videoLink, imgSrc, imgAlt, imgText, reverse, idLink, bgColor }) => {
  return (
    <StyledSectionWrapper id={idLink} style={bgColor ? {backgroundColor: bgColor} : null}>
      <StyledContainerWrapper>
        {
          reverse ? (
            <StyledTwoColumnsGridWrapper>
              <div className="alignSelfCenter mobileHideTwoColumns">
                {
                  videoLink ? (
                    <StyledVideoWrapper>
                      <div className="videoAspectRatio">
                        <iframe
                          loading="lazy"
                          title={title}
                          src={videoLink}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </StyledVideoWrapper>
                  ) : null
                }
                {
                  imgSrc ? (
                    <div className="gridImg">
                      <img loading="lazy" src={imgSrc} alt={imgAlt} />
                      {imgText ? <StyledDesc2 css={textCenter}>{imgText}</StyledDesc2> : null}
                    </div>
                  ) : null
                }
              </div>
              <div className="alignSelfCenter">
                <StyledSubTitle1 paddingBottom="pb16">{title}</StyledSubTitle1>
                {
                  list ? (
                    <StyledDesc1>
                      <ul>
                        {
                          list.map((item, index) => (
                            <li>
                              <Icon className="leftIcon mt6" variant="check_mark" color="sky_80" size="sm" />
                              {item.desc}
                            </li>
                          ))
                        }
                      </ul>
                    </StyledDesc1>
                  ) : null
                }
                {
                  subDescription ? (
                    <StyledDesc1>
                      {subDescription}
                    </StyledDesc1>
                  ) : null
                }
              </div>
              <div className="alignSelfCenter mobileShowTwoColumns">
                {
                  videoLink ? (
                    <StyledVideoWrapper>
                      <div className="videoAspectRatio">
                        <iframe
                          loading="lazy"
                          title={title}
                          src={videoLink}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </StyledVideoWrapper>
                  ) : null
                }
                {
                  imgSrc ? (
                    <div className="gridImg">
                      <img loading="lazy" src={imgSrc} alt={imgAlt} />
                      {imgText ? <StyledDesc2 css={textCenter}>{imgText}</StyledDesc2> : null}
                    </div>
                  ) : null
                }
              </div>
            </StyledTwoColumnsGridWrapper>
          ) : (
            <StyledTwoColumnsGridWrapper>
              <div className="alignSelfCenter">
              <StyledSubTitle1 paddingBottom="pb16">{title}</StyledSubTitle1>
                {
                  list ? (
                    <StyledDesc1>
                      <ul>
                        {
                          list.map((item, index) => (
                            <li>
                              <Icon className="leftIcon mt6" variant="check_mark" color="sky_80" size="sm" />
                              {item.desc}
                            </li>
                          ))
                        }
                      </ul>
                    </StyledDesc1>
                  ) : null
                }
                {
                  subDescription ? (
                    <StyledDesc1>
                      {subDescription}
                    </StyledDesc1>
                  ) : null
                }
              </div>
              <div className="alignSelfCenter">
                {
                  videoLink ? (
                    <StyledVideoWrapper>
                      <div className="videoAspectRatio">
                        <iframe
                          loading="lazy"
                          title={title}
                          src={videoLink}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </StyledVideoWrapper>
                  ) : null
                }
                {
                  imgSrc ? (
                    <div className="gridImg">
                      <img loading="lazy" src={imgSrc} alt={imgAlt} />
                      {imgText ? <StyledDesc2 css={textCenter}>{imgText}</StyledDesc2> : null}
                    </div>
                  ) : null
                }
              </div>
            </StyledTwoColumnsGridWrapper>
          )
        }
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default TwoGridColumnsWrapper;

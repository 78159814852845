import React from "react";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";
import PowerfulQuery from "../../../components/allfeatures/powerfulquery";
import TwoGridColumnsWrapper from "../../../components/shared/TwoGridColumnsWrapper";
import AddExisting from "../../../components/allfeatures/addexisting";
import GraphQLCommunity from "../../../components/stylegraphql/graphqlcommunity";

import { COLORS } from "../../../globals/designSystem";

import timescaledb from "../../../components/database/images/timescaledb.svg";
import yugabyte from "../../../components/database/images/yugabyte.svg";
import aurorapostgres from "../../../components/database/images/aurorapostgres.svg";
import postgis from "../../../components/database/images/postgis.svg";
import optimisePostgres from "../../../components/database/images/optimise-postgres.png";
// import plusGray from "../../../components/graphql/images/plus-gray.svg";

import postgresImg from "../../../components/database/images/postgres.svg";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/postgresql_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/postgresql/";

const triggerLists = [
  { desc: "Hasura captures changes to PostgreSQL tables and triggers webhooks" },
  { desc: "Atomic capture, reliable delivery, horizontally scalable." },
];

const railsLists = [
  {
    desc: "Using Hasura Console in migration mode, the database migration files are automatically created.",
  },
  {
    desc: "up/down migrations for going back and forth and reverting changes.",
  },
];

/*
const connectionPoolerLists = [
  { desc: "For managing connections at scale" },
  { desc: "Automatic prepared statements" },
]

const postgreSQLToolingLists = [
  { desc: "Metabase" },
  { desc: "adminer and pg_admin" },
]
*/

const consoleList = [
  {
    desc: "Hasura comes with a Console admin UI that can be used to manage the database schema and adding/modifying records.",
  },
  {
    desc: "The UI can also be used with CLI to manage database migrations automatically.",
  },
];

const communityEcosystem = [
  {
    name: "TimescaleDB - Timeseries Database",
    imgSrc: timescaledb,
    imgAlt: "TimescaleDB",
    link: "https://hasura.io/blog/using-timescaledb-with-hasura-graphql-d05f030c4b10/",
  },
  {
    name: "YugabyteDB - Distributed",
    imgSrc: yugabyte,
    imgAlt: "YugabyteDB",
    link: "https://docs.yugabyte.com/latest/integrations/hasura/hasura-1/",
  },
  {
    name: "Aurora PostgreSQL - Serverless",
    imgSrc: aurorapostgres,
    imgAlt: "Aurora PostgreSQL",
    link: "https://github.com/hasura/hasura-aws-stack",
  },
  {
    name: "PostGIS - Geo PostgreSQL Extension",
    imgSrc: postgis,
    imgAlt: "PostGIS",
    link: "https://hasura.io/blog/tagged/postgis/",
  },
];

const PostgreSQL = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant REST APIs on PostgreSQL Database from Hasura"
      description="Connect Hasura to existing/new PostgreSQL DBs for instant, REST APIs. Leverage PostgreSQL native types, functions & optimize for performance."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST API for PostgreSQL"
      desc="Hasura connects to PostgreSQL database and gives Instant REST APIs"
      btnLink="https://cloud.hasura.io/signup?pg=postgresql&plcmt=body&cta=try-it-out-in-30-seconds&tech=default"
      btnText="Try it Out in 30s"
      imgSrc={postgresImg}
    />
    <PowerfulQuery type="REST API" />
    <TwoGridColumnsWrapper
      title="Trigger webhooks on database events"
      list={triggerLists}
      videoLink="https://www.youtube.com/embed/EaTUVWnDCvA?showinfo=0&rel=0"
      reverse
      idLink="triggerWebhooks"
    />
    <AddExisting type="REST" />
    <TwoGridColumnsWrapper
      title="Rails like Database migrations for PostgreSQL"
      list={railsLists}
      videoLink="https://www.youtube.com/embed/cGjH_HjkWiU?showinfo=0&rel=0"
      reverse
      idLink="railsDatabase"
      videoTitle="Rails like Database migrations for PostgreSQL"
    />
    <TwoGridColumnsWrapper
      title="Optimise PostgreSQL performance using explain"
      subDescription="Hasura supports PostgreSQL read replicas that can be used automatically to load-balance your REST API queries."
      imgSrc={optimisePostgres}
      imgAlt="Optimise PostgreSQL"
      bgColor={`${COLORS.grey_4}`}
      reverse={false}
      idLink="optimisePostgres"
    />
    <TwoGridColumnsWrapper
      title="Manage PostgreSQL data with Hasura Console"
      imgSrc={"https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-console-postgresql.png"}
      imgAlt="Manage PostgreSQL"
      reverse
      idLink="managePostgres"
      list={consoleList}
    />
    <GraphQLCommunity
      title="PostgreSQL flavors and extensions that work with Hasura"
      desc="Hasura works natively with different PostgreSQL flavors and extensions"
      list={communityEcosystem}
    />
  </Layout>
);

export default PostgreSQL;
